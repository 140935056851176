import React, { useCallback, useEffect, useState } from 'react';
import { BsCheckCircle } from "react-icons/bs";
import JobTable from './components/JobTable';
import HeaderOptions from './components/HeaderOptions';
import FooterOptions from './components/FooterOptions';
import TemplateView from 'Components/Template';
import { JobServices, LoaderErrorMessage, LoaderOverview } from './Jobs.styles'
import Loader from 'Components/Loader';
import TariffModal from 'Components/TariffModal';
import PegModal from 'Controls/PegModal';
import axios from 'axios';
import EditForm from 'Containers/ServiceGrid/Components/EditForm';
import moment from "moment";
import { onBulkEdit, onBulkEditAll, applyTariff, getStatus } from 'Utils/JobGrid';
import { QuotationAlert } from 'Containers/Quotation/quotation.styles';
import PriceSummary from 'Containers/PriceSummary';
import Preferences from 'Components/ColumnPreference';
import { JOB_COLUMN } from 'Constants/ColumnFilterConstants';
import { getExchangeRates, shouldRenderBilling, shouldRenderOperatingIncome, shouldRenderServRequest, showToast, validateTemplate } from 'Utils/Generic';
import SummaryDetails from './components/SummaryDetails';
import _ from 'lodash';
import { getUserAccount } from 'Utils/AuthConfig';
import { BILLING_STATUS, BILLING_STATUS_OPTIONS_LIST, JobStatus, UnitPriceMinimum } from 'Constants/Constant';
import { toast } from 'react-toastify';
import PegButton from 'Controls/Button/PegButton';
import GridErrors from 'Components/GridErrors';
import { getGridErrorData } from 'Components/GridErrors/utils';
import { ICharge } from 'Model/Job/JobCharge';
import { ColumnPreferences } from 'Model/Common/ColumnPreferences';
import { IColumnGroupingPreferences, IJobDetails, IOperationalProcess, IServicesAndCharges } from 'Model/Job/types';
import { IValidationConfig } from 'Model/Common/types';
import QuotationSummary from "Components/QuotationSummary";
const JobGrid = (props: any) => {
  const [selectedList, setSelectedList] = useState<any>([]);
  const [isAnyServiceWithSupplierInvoiceOrCRSelected, setIsAnyServiceWithSupplierInvoiceOrCRSelected] = useState(false);
  const [operationalProcesses, setOperationalProcesses] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [uomList, setUomList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [billingParties, setBillingParties] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [exchangeRates, setExchangeRates] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [exchangeRatesLoading, setExchangeRatesLoading] = useState(true);
  const [jobDetail, setJobDetail] = useState<any>(null);
  const [expandAll, setExpandAll] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [openOnlyBillingStatus, setOpenOnlyBillingStatus] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [disableOperatingIncome, setDisableOperatingIncome] = useState<any>(false);
  const [disableRevenue, setDisableRevenue] = useState(false);
  const [disableCost, setDisableCost] = useState(false);
  const [disableServiceRequest, setDisableServiceRequest] = useState(false);
  const [disableBilling, setDisableBilling] = useState(false);
  const [hasMissingFields, setHasMissingFields] = useState(false);
  const [bulkEditList, setBulkEditList] = useState<any>({});
  const [bulkEditSR, setBulkEditSR] = useState<any>({});
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [showUndoModal, setShowUndoModal] = useState(false);
  const [showPriceSummaryModal, setShowPriceSummaryModal] = useState(false);
  const [isPriceSummaryPosting, setIsPriceSummaryPosting] = useState(false);
  const [showPartyValidModal, setShowPartyValidModal] = useState(false);
  const [missingValidation, setMissingValidation] = useState([]);
  const [showTarrifModal, setShowTarrifModal] = useState(false);
  const [tariffData, setTariffData] = useState<any>(null);
  const [applyingTarriff, setApplyingTarriff] = useState(false);
  const [isValidParty, setIsValidParty] = useState(true);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [columnPreference, setColumnPreference] = useState(JOB_COLUMN);
  const [showConfirmBulkUpdate, setShowConfirmBulkUpdate] = useState(false);
  const [gridErrors, setGridErrors] = useState<any>([]);
  const [exchangeError, setExchangeError] = useState(false);
  const [deleteConfirmationMessage, setDeleteConfirmationMessage] = useState<any>("");
  const [noneOfTheServicesCanBeDeleted, setNoneOfTheServicesCanBeDeleted] = useState(false);
  const [showVesselMeasureModal, setShowVesselMeasureModal] = useState(false);
  const [manualSelect, setManualSelect] = useState(false);
  const { match, userDetails, jobDetails, getUserDetail, getJobGridServices, getVoucherList, exchangeRate, setAddServiceLoading, addServices, onBulkUpdate, onMultiDeleteServices, onSaveJobList, chargeValidation, serviceValidation, masterChargeValidation, masterServiceValidation, tariffServiceValidation, tariffChargeValidation, onAddServiceTemplate, undoChanges, onUpdateTariff, isLoading, isError, isRefreshLoader, message, addCharges, deleteServices, deleteCharges, onUpdateService, onUpdateEditService, onUpdateCharge, isJobSaving, isJobSaved, onUpdateServiceDetails, isModified, isServiceAdding, voucherList, updateChargeValidation, isViewer } = props;

  const services = props.services;
  useEffect(() => {

    const userName = getUserAccount('userName');
    getUserDetail(userName);
  }, [])

  useEffect(() => {
    let jobColumnGroupingPreferences: any = {};

    if (localStorage?.jobColumnGroupingPreferences) {
      jobColumnGroupingPreferences = JSON.parse(localStorage.jobColumnGroupingPreferences);
      setDisableOperatingIncome(jobColumnGroupingPreferences.disableOperatingIncome || false);
      setDisableRevenue(jobColumnGroupingPreferences.disableRevenue || false);
    }

    if (Object.keys(jobDetails).length && !jobDetail) {
      setJobDetail(jobDetails);
      setDisableOperatingIncome(jobColumnGroupingPreferences.disableOperatingIncome || jobDetails.IsMaster || false);
      setDisableRevenue(jobColumnGroupingPreferences.disableRevenue || jobDetails.IsMaster || false);

      // Replace the following line with an actual method call if needed
      getDropdownValues(jobDetails);
    }

    if (userDetails?.Code && !Object.keys(jobDetails).length) {
      // Replace the following line with an actual method call if needed
      getJobServices(match);

      if (localStorage?.jobColumnPreference) {
        const jobColumnsKeys = Object.keys(JOB_COLUMN);
        const columnObj = JSON.parse(localStorage.jobColumnPreference);

        if (columnObj[userDetails?.Username]) {
          const columnPreferencesKeys = Object.keys(columnObj[userDetails?.Username]);
          const jobsColumnsKeysString = jobColumnsKeys.sort().join();
          const columnPreferencesKeysString = columnPreferencesKeys.sort().join();

          if (jobsColumnsKeysString === columnPreferencesKeysString) {
            setColumnPreference(columnObj[userDetails?.Username]);
          } else {
            localStorage.removeItem("jobColumnPreference");
          }
        }
      }
    }

    if (props.jobDetails?.IsMaster) {
      setDisableRevenue(true);
      setDisableBilling(true);
    }
  }, [jobDetails, jobDetail, userDetails, match]);

  const getJobServices = (match: any) => {
    const {
      params,
    } = match
    getJobGridServices(params)
  }


  const getDropdownValues = (jobDetail: any) => {
    getServices(jobDetail);
    getCurrencies();
    getUOM();
    getTaxes(jobDetail);
    getBillingParties(jobDetail);
    getExchangeRate(jobDetail);
    getVoucherList(jobDetail?.CompanyCode);
    getPaymentTerms()
  }

  const getPaymentTerms = () => {
    axios.get(`/getPaymentTerms`).then((response) => {
      setPaymentTerms(response.data)
    })
  }

  const getServices = (jobDetail: IJobDetails) => {

    axios.get(`/jobService-GetAllBpServices?jobId=${jobDetail.LocalJobId}&bpCode=${jobDetail.ProductCode}&companyCode=${jobDetail.CompanyCode}&opId=${match?.params.opId ? match?.params.opId : ''}`
    ).then((response) => {
      setServiceList(response.data)
    }).catch((error) => {
      toast.error(error.data.message ?? 'Failed to get Services', {
        position: 'top-center',
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        style: { width: '400px' },
      });
    })
  }

  const getUOM = () => {
    axios.get(`/mdm-unit-of-measure?uomShortNames=&uomCodes=&searchText=`).then((response) => {
      setUomList(response.data)
    })
  }

  const getCurrencies = () => {
    axios.get(`/mdm-currency?searchText=&code=`).then((response) => {
      setCurrencyList(response.data)
    })
  }

  const getBillingParties = (jobDetail: any) => {

    if (match?.params.opId) {
      axios.get(`/jobService-getAllPartiesByOpId?opId=${match?.params.opId}`).then((responseChild) => {
        const filteredData = responseChild.data.filter((item: any) => item.isBillingParty)
        setBillingParties(filteredData)
      })
    } else {
      if (jobDetail.LocalJobId) {
        axios.get(`/jobService-getAllOpProcessByJobId?jobId=${jobDetail.LocalJobId}`).then((response) => {
          if (!response.data.errorCode) {
            setOperationalProcesses(response.data)

            const optionIds = response.data.map((item: any) => item.JobOperationalProcessId)
            if (optionIds || optionIds.length) {
              axios.get(`/jobService-getAllPartiesByOpId?opId=${optionIds}`).then((responseChild) => {
                const filteredData = responseChild.data.filter((item: any) => item.isBillingParty)
                setBillingParties(filteredData)
              })
            }
          }
        })
      }
    }
  }

  const getTaxes = (jobDetail: any) => {
    jobDetail && axios.get(`/oum-get-tax?company-code=${jobDetail.CompanyCode}`).then((response) => {
      setTaxList(response.data)
    })
  }

  const getExchangeRate = async (jobDetail: any) => {

    const exchangeErrorTimeout = setTimeout(function () {
      setExchangeError(true)
    }, 5000);

    axios.get(`/jobService-getAllCurrencyExchangeRates?company-code=${jobDetail.CompanyCode}`).then((response: any) => {
      clearTimeout(exchangeErrorTimeout);
      if (response.data) {
        setExchangeRates(response.data)
        setExchangeRatesLoading(false)
      } else {
        setExchangeError(true)
      }
    }).catch(() => {
      setExchangeError(true)
    })

  }

  const onAddService = async () => {
    const element = document.getElementById('jobTableView');
    await setAddServiceLoading(true);
    await addServices();
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }

  const onEditOption = () => {


    let openOnlyBillingStatus = false;

    for (let i = 0; i < selectedList.length; i++) {
      const service = services[selectedList[i]];

      let emptyBillingStatusForCharges = true;

      service.charges?.forEach((charge: any) => {
        if (charge.BillingStatus !== "") {
          emptyBillingStatusForCharges = false;
        }
      });

      if (!openOnlyBillingStatus) {
        openOnlyBillingStatus = openOnlyBillingStatus || !emptyBillingStatusForCharges;
      } else {
        break;
      }
    }

    setShowEditModal(true)
    setOpenOnlyBillingStatus(openOnlyBillingStatus)
  }

  const onEditOptionCancel = () => {
    setShowEditModal(false)
  }

  const editOptionConfirmPreCheck = () => {

    let selectedServices = services.filter((x: any, index: any) => selectedList.indexOf(index) != -1);
    const billableServices = services.filter((srvObj: any) => (
      srvObj.charges?.some((charge: any) => (
        charge.BillingStatus === BILLING_STATUS.ASSIGNED ||
        charge.BillingStatus === BILLING_STATUS.BILLING_IN_PROGRESS))) || srvObj.service.BillingStatus === BILLING_STATUS.SEND_FOR_BILLING);

    const hasNoBillable = billableServices.findIndex((srvObj: any) => !!srvObj?.service?.IsBillable)

    if (selectedServices.every((x: any) => !x.service.IsBillable) && bulkEditList.BillingStatus != "" && billableServices?.length > 0 && hasNoBillable == -1) {
      setShowConfirmBulkUpdate(true)
    }
    else {
      onEditOptionConfirm();
    }
  }

  const onEditOptionConfirm = () => {



    let updatedList =
      isAllSelected ?
        onBulkEditAll(services, selectedList, bulkEditList, bulkEditSR, jobDetails, exchangeRates)
        :
        onBulkEdit(services, selectedList, bulkEditList, bulkEditSR, jobDetails, exchangeRates);
    onBulkUpdate(updatedList)

    setShowEditModal(false);
    setSelectedList([]);
    setIsAllSelected(false);
    setBulkEditList({});
    setBulkEditSR({})
    setIsAnyServiceWithSupplierInvoiceOrCRSelected(false)
  }

  const onBulkUpdateDenied = () => {

    setShowConfirmBulkUpdate(false)
  }
  const onBulkUpdateConfirmed = () => {

    setShowConfirmBulkUpdate(false)
    onEditOptionConfirm();
  }

  const setPartyValidModal = (value: boolean) => {
    setShowPartyValidModal(value)
  }


  const onEditServices = useCallback((event: any, key: any) => {
    setBulkEditSR((prev: any) => {
      const updatedBulkEditSR = { ...prev };
      if (key === 'startDate') {
        updatedBulkEditSR.StartDateTime = moment(event.value).format();
      }
      if (key === 'editDate') {
        updatedBulkEditSR.EndDateTime = moment(event.value).format();
      }
      if (key === 'serviceStatus') {
        updatedBulkEditSR.ServiceStatus = event ? event.label : '';
      }
      return updatedBulkEditSR;
    });

    setBulkEditList((prev: any) => {
      const updatedBulkEditList = { ...prev };
      if (key === 'billingStatus') {
        if (event) {
          if (event.name === "Send for Billing") {
            setPartyValidModal(true);
            getStatus(jobDetails, services, null, billingParties, 'bulkEdit', selectedList, isAllSelected)
              .then((response: any) => {
                setValidParty(response?.IsValidParty);
                if (response?.IsValidParty) {
                  setPartyValidModal(false);
                  showToast('Party is valid, you can proceed for "Send to Billing"', 'success', 7000, 'top-center', 'colored');
                  updatedBulkEditList.BillingStatus = event.name;
                } else {
                  setPartyValidModal(false);
                  updatedBulkEditList.BillingStatus = "";
                  response?.Message.forEach((el: any) => {
                    showToast(`${el}, cannot be "Send to Billing" !`, 'error', 7000, 'top-center', 'colored');
                  });
                }
              });
          } else {
            updatedBulkEditList.BillingStatus = event.name;
          }
        } else {
          updatedBulkEditList.BillingStatus = "";
        }
      }
      if (key === 'billingCurrency') {
        updatedBulkEditList.BillingCurrencyCode = event ? event.code : "";
      }
      if (key === 'priceCurrency') {
        const priceExRate = getConvertionRate(event?.code, jobDetails?.CurrencyCode);
        updatedBulkEditList.PriceCurrencyCode = event ? event.code : "";
        updatedBulkEditList.PriceExchangeRate = priceExRate ? priceExRate.ExchangeRate : 0;
      }
      if (key === 'costCurrency') {
        const costExRate = getConvertionRate(event?.code, jobDetails?.CurrencyCode);
        updatedBulkEditList.CostCurrencyCode = event ? event.code : "";
        updatedBulkEditList.CostExchangeRate = costExRate ? costExRate.ExchangeRate : 0;
      }
      if (key === 'supplier') {
        updatedBulkEditList.SupplierName = event ? event.name : "";
        updatedBulkEditList.SupplierCode = event ? event.code : "";
      }
      if (key === 'IsBillable') {
        updatedBulkEditList.IsBillable = event?.value;
        if (!event?.value) {
          updatedBulkEditList.BillingStatus = '';
        }
      }
      return updatedBulkEditList;
    });
  }, [jobDetails, services, billingParties, selectedList, isAllSelected]);

  const getConvertionRate = (fromCurrency: any, targetCurrency: any) => {

    let exchangeRate: any = getExchangeRates(exchangeRates, fromCurrency, targetCurrency)
    return exchangeRate;
  }

  const onExpandAll = () => {
    setExpandAll(!expandAll)
  }

  const onDeleteOption = () => {

    let cannotDeleteList: string[] = [];

    let deleteConfirmationMessage;
    let noneOfTheServicesCanBeDeleted = false;

    selectedList.map((serviceIndex: number) => {
      const serviceAndCharges = services[serviceIndex];

      const isSupplierInvoiceAvailable = serviceAndCharges.supplierDetails?.supplierInvoice?.[0];
      const isSupplierCreditNoteAvailable = serviceAndCharges.supplierDetails?.creditNote?.[0];

      const canDeleteService = !(BILLING_STATUS_OPTIONS_LIST.includes(serviceAndCharges.service?.BillingStatus!) || isSupplierInvoiceAvailable || isSupplierCreditNoteAvailable || serviceAndCharges.service?.VoucherStatus === "Approved");

      if (!canDeleteService) {
        cannotDeleteList.push(serviceAndCharges?.service?.Name!);
      }
    })

    if (cannotDeleteList.length > 0) {
      deleteConfirmationMessage = <div>
        These services cannot be deleted as they have approved vouchers ({cannotDeleteList.join(', ')}).
        {cannotDeleteList.length < selectedList.length ? <>
          <br /><br />
          Delete other selected services?
        </> : null}
      </div>;

      if (cannotDeleteList.length < selectedList.length) {
        noneOfTheServicesCanBeDeleted = false;
      } else {
        noneOfTheServicesCanBeDeleted = true
      }

    } else {
      deleteConfirmationMessage = "Are you sure to delete the selected items";
      noneOfTheServicesCanBeDeleted = false;
    }

    setShowDeleteModal(true)
    setDeleteConfirmationMessage(deleteConfirmationMessage)
    setNoneOfTheServicesCanBeDeleted(noneOfTheServicesCanBeDeleted)
  }

  const onDeleteModalCancel = () => {
    setShowDeleteModal(false)
  }

  const onDeleteModalConfirm = async () => {

    let deleteList: number[] = [];

    selectedList.map((serviceIndex: number) => {
      const serviceAndCharges = services[serviceIndex];

      const isSupplierInvoiceAvailable = serviceAndCharges.supplierDetails?.supplierInvoice?.[0];
      const isSupplierCreditNoteAvailable = serviceAndCharges.supplierDetails?.creditNote?.[0];

      const canDeleteService = !(BILLING_STATUS_OPTIONS_LIST.includes(serviceAndCharges.service?.BillingStatus!) || isSupplierInvoiceAvailable || isSupplierCreditNoteAvailable || serviceAndCharges.service?.VoucherStatus === "Approved");

      if (canDeleteService) {
        deleteList.push(serviceIndex);
      }
    })

    await onMultiDeleteServices(deleteList)
    setShowDeleteModal(false)
    setSelectedList([])
    setIsAllSelected(false)
  }

  const onSelectServices = (index: number) => {
    setManualSelect(true);
    let newSelectedList = [...selectedList]; // Create a copy of the selectedList array

    if (!newSelectedList.includes(index)) {
      newSelectedList.push(index);
    } else {
      newSelectedList.splice(newSelectedList.indexOf(index), 1);
    }

    setSelectedList(newSelectedList);
    setIsAllSelected(false);
    setIsAnyServiceWithSupplierInvoiceOrCRSelected(getIsAnyServiceWithSupplierInvoiceOrCR(newSelectedList));
  };

  const onClickOptions = (type: string) => {

    const jobColumnGroupingPreferences: IColumnGroupingPreferences = {
      disableRevenue,
      disableCost,
      disableServiceRequest,
      disableOperatingIncome,
      disableBilling
    }

    if (type === 'actuals') {
      jobColumnGroupingPreferences.disableRevenue = !disableRevenue;
      setDisableRevenue(jobColumnGroupingPreferences.disableRevenue)
    }
    if (type === 'estimates') {
      jobColumnGroupingPreferences.disableCost = !disableCost;
      setDisableCost(jobColumnGroupingPreferences.disableCost)
    }
    if (type === 'SrRequest') {
      jobColumnGroupingPreferences.disableServiceRequest = !disableServiceRequest;
      setDisableServiceRequest(jobColumnGroupingPreferences.disableServiceRequest)
    }
    if (type === 'OpIncome') {
      jobColumnGroupingPreferences.disableOperatingIncome = !disableOperatingIncome;
      setDisableOperatingIncome(jobColumnGroupingPreferences.disableOperatingIncome)
    }
    if (type === 'billing') {
      jobColumnGroupingPreferences.disableBilling = !disableBilling;
      setDisableBilling(jobColumnGroupingPreferences.disableBilling)
    }

    localStorage.setItem('jobColumnGroupingPreferences', JSON.stringify(jobColumnGroupingPreferences));
  }

  const onSave = async () => {

    if (services.length > 0) {
      const isValidated = await checkValidation(services, 'saveServices')
      if (isValidated) {
        onSaveJobList(services, jobDetails, match.params.id, match.params.opId)
        setHasMissingFields(false)
      } else {
        setHasMissingFields(true)
      }
    }
  }

  const onClickImportTemplate = () => {
    setShowTemplateModal(true)
  }

  const onCloseTemplateModal = () => {
    setShowTemplateModal(false)
  }


  const onSubmitImportTemplate = async (template: any) => {

    const operationProcessIds = operationalProcesses.map((op: IOperationalProcess) => op.JobOperationalProcessId);

    const operationalProcessId = match?.params.opId;

    let templatesToImport;

    if (operationalProcessId) {
      templatesToImport = template.filter((service: any) => {
        return service?.services?.OperationalProcessFk == operationalProcessId;
      });
    } else {
      templatesToImport = template.filter((service: any) => {
        return operationProcessIds.includes(service?.services?.OperationalProcessFk);
      });
    }

    const { CompanyCode, CustomerCode } = jobDetails
    const { errors, services } = await validateTemplate(templatesToImport, CompanyCode, CustomerCode, jobDetails.IsMaster)

    errors.forEach((error: any) => {
      toast.error(error.message, {
        position: "top-center",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        style: { width: '400px' },
      })
    })

    onAddServiceTemplate(services)

    setTimeout(() => {
      setShowTemplateModal(false)
    }, 1500);
  };



  const onUndoChange = () => {
    setShowUndoModal(true)
  }

  const onUndoModalCancel = () => {
    setShowUndoModal(false)
  }

  const onUndoModalConfirm = () => {
    undoChanges(match.params)
    setShowUndoModal(false)
  }


  const onClickPriceSummary = () => {
    setShowPriceSummaryModal(true)
  }

  const onClosePriceSummary = () => {
    setShowPriceSummaryModal(false)
  }

  const sendPricingSummary = (pricingSummary: any) => {
    postPricingSummary(pricingSummary)

  }

  const postPricingSummary = (pricingSummary: any) => {
    setIsPriceSummaryPosting(true)
    axios.post(`/generatePriceSummary`, {
      "LocalJobId": jobDetails.LocalJobId,
      "PriceSummaryData": [...pricingSummary.priceSummaryData]
    }).then(() => {
      setShowPriceSummaryModal(false)
      setIsPriceSummaryPosting(false)
    })
  }



  useEffect(() => {
    if (!manualSelect) {
      const service = services.filter((service: any) => service.service.IsActive);

      if (isAllSelected) {
        const tempSelectedList = service.reduce((list: any, item: any, index: any) => {
          const isChargeExist = item.charges.some((charge: any) => !charge.BillingStatus || !BILLING_STATUS_OPTIONS_LIST.includes(charge.BillingStatus));

          if (isChargeExist) {
            list.push(index);
          }
          return list;
        }, []);

        setSelectedList(tempSelectedList);
        setIsAnyServiceWithSupplierInvoiceOrCRSelected(getIsAnyServiceWithSupplierInvoiceOrCR(tempSelectedList));
      } else {
        setSelectedList([]);
        setIsAnyServiceWithSupplierInvoiceOrCRSelected(false);
      }
    }
  }, [isAllSelected, manualSelect])

  const onSelectAll = () => {
    setManualSelect(false);
    setIsAllSelected((prev) => {
      return !prev
    });
  };

  const onApplyTariff = async () => {


    let list = services;
    if (!isAllSelected) {
      list = selectedList.map((item: number) => services[item])
    } else {
      list = services.filter((item: any) => item.service.IsActive && !BILLING_STATUS_OPTIONS_LIST.includes(item.service.BillingStatus))
    }
    let isValidated = await checkValidation(list, 'tariff')
    if (isValidated) {
      setApplyingTarriff(true)
      axios.post(`/jobService-getTariffDetails`, {
        ServicesAndCharges: list
      }).then((response) => {
        let updatedServices = applyTariff(selectedList, services, response.data, isAllSelected)
        onUpdateTariff(updatedServices)
        setApplyingTarriff(false)
        setSelectedList([])
        setIsAllSelected(false)
        setIsAnyServiceWithSupplierInvoiceOrCRSelected(false)
      }).catch((err) => {
        setApplyingTarriff(false)
        setSelectedList([])
        setIsAllSelected(false)
        setIsAnyServiceWithSupplierInvoiceOrCRSelected(false)
      })
    }
  }

  const checkValidation = async (services: any, type: string) => {

    let isValidated = true;
    let serValidation = jobDetails.IsMaster ? masterServiceValidation : serviceValidation;
    let charValidation = jobDetails.IsMaster ? masterChargeValidation : chargeValidation;
    if (type === 'tariff') {
      serValidation = tariffServiceValidation
      charValidation = tariffChargeValidation
    }

    let errors: any[] = [];

    let missingValidation: any = []

    await services.forEach((obj: any) => {

      if (obj.service.IsActive) {
        Object.keys(serValidation).forEach(function (key) {
          if (!obj.service[key]) {
            isValidated = false;
            missingValidation.push(key);
            errors.push(getGridErrorData({ service: obj?.service, key, type: "required" }));
          }
        })

        if (obj.service.IsCashVoucherNeeded && !obj.service.VoucherType) {
          isValidated = false;
          missingValidation.push("Voucher Type");
          errors.push(getGridErrorData({ service: obj?.service, key: "VoucherType", type: "required" }));
        }

        obj.charges.forEach((charge: any) => {
          if (charge.IsActive) {
            Object.keys(charValidation).forEach((key) => {
              if (key === 'UnitPrice') {
                let unitPrice = charge[key];
                if (!unitPrice && unitPrice !== 0) {
                  isValidated = false;
                  missingValidation.push(key);
                  errors.push(getGridErrorData({ service: obj?.service, charge, key, type: "required" }));
                }
                else if (!(unitPrice >= UnitPriceMinimum) && obj.service.IsBillable) {
                  isValidated = false;
                  missingValidation.push('UnitPrice0');
                  errors.push(getGridErrorData({ service: obj?.service, charge, key, type: "required" }));
                }
              }
              else if (key === 'CustomerService') {
                const customerServiceValidation = charValidation[key] as IValidationConfig;
                const shouldValidateCharge = customerServiceValidation[charge.Code as string];

                if (charge.Id === 0 && shouldValidateCharge && !charge?.GSServiceCode) {
                  isValidated = false;
                  missingValidation.push(key);
                  errors.push(getGridErrorData({ service: obj?.service, charge, key, type: "required" }));
                }
              }
              else if (key === 'dolphinCode') {

                const customerServiceValidation = charValidation[key] as IValidationConfig;
                const shouldValidateCharge = customerServiceValidation[charge.Code as string];

                if (charge.Id === 0 && shouldValidateCharge) {
                  isValidated = false;
                  missingValidation.push(key);
                  errors.push(getGridErrorData({
                    service: obj?.service,
                    charge,
                    key: "DolphinCode",
                    type: "dolphinCode",
                    message: " - (Note that the charge selected has missing dolphin codes. Please contact finance/accounts to check and resolve)"
                  }
                  ));
                }
              }
              else if (key === 'OperationalProcessPartyFk') {
                const isValid = billingParties.some((party: any) => party.operationalProcessPartyId === charge.OperationalProcessPartyFk)

                if (!isValid) {
                  isValidated = false;
                  missingValidation.push(key);
                  errors.push(getGridErrorData({ service: obj?.service, charge, key, type: "required" }));
                }
              }
              else if (!charge[key]) {
                if (key === 'UnitCost') {
                  if (isNaN(charge[key]) || charge[key] === null || charge[key] === undefined || charge[key] === '') {
                    isValidated = false;
                    missingValidation.push(key);
                    errors.push(getGridErrorData({ service: obj?.service, charge, key, type: "required" }));

                  }
                }
                else {
                  isValidated = false;
                  missingValidation.push(key);
                  errors.push(getGridErrorData({ service: obj?.service, charge, key, type: "required" }));

                }
              }
            })
          }
        })


      }
    })

    const billableServices = services.filter((srvObj: any) => (
      srvObj.charges?.some((charge: any) => (
        charge.BillingStatus === BILLING_STATUS.ASSIGNED ||
        charge.BillingStatus === BILLING_STATUS.BILLING_IN_PROGRESS))) || srvObj.service.BillingStatus === BILLING_STATUS.SEND_FOR_BILLING);

    const hasNoBillable = billableServices.findIndex((srvObj: any) => !!srvObj?.service?.IsBillable || srvObj.charges.some((charge: any) => charge.BillingStatus === BILLING_STATUS.BILLING_IN_PROGRESS))

    if (billableServices?.length > 0 && hasNoBillable == -1) {
      isValidated = false;
      errors.push(getGridErrorData({
        service: null,
        charge: null,
        key: "Missing Job Revenue",
        type: "missingJobRevenue",
        message: "Please note that the selected service lines do not have Job Revenue and cannot be sent to billing. Kindly add other services with Job Revenue to proceed."
      }
      ));
    }

    if (type !== 'tariff') {
      setMissingValidation(_.uniq(missingValidation))
    }
    setGridErrors(errors)

    return isValidated;
  }

  const setValidParty = (res: boolean) => {
    setIsValidParty(res)
  }
  const onCloseTariffModal = () => {
    setShowTarrifModal(false)
  }

  const onShowTariffModal = (value: ICharge) => {
    setShowTarrifModal(true)
    setTariffData(value)
  }

  const onClickFilterColumn = () => {
    setShowFilterModal(!showFilterModal)
  }


  const onSavePreferences = (list: ColumnPreferences) => {

    let columnObj: any = {
      [userDetails?.Username]: list
    }
    localStorage.setItem('jobColumnPreference', JSON.stringify(columnObj))
    setShowFilterModal(false)
    setColumnPreference(list)
    toast.success('Column Preferences Updated', {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
      style: { width: '400px' },
    })
  }

  const getIsAnyServiceWithSupplierInvoiceOrCR = (selectedIndexes: number[]) => {
    return !!selectedIndexes
      .map((i: number) => services[i])
      .find((s: any) => !!(s?.supplierDetails?.supplierInvoice?.[0] || s?.supplierDetails?.creditNote?.[0]))
  }

  const showOperatingIncome = shouldRenderOperatingIncome(columnPreference);
  const showServReq = shouldRenderServRequest(columnPreference);
  const showBilling = shouldRenderBilling(columnPreference);
  const isGridReadonly = jobDetails?.JobStatusFk === JobStatus.FinanciallyClosed || isViewer

  if (isRefreshLoader) {
    return (
      <div id="refreshModal">
        <PegModal
          isOpen={isRefreshLoader}
          alertModal={true}
          showTemplate={true}
          isCenter={true}>
          <div style={{ overflow: 'hidden', padding: 30 }}>
            <QuotationAlert>{message}</QuotationAlert>
            <Loader gridLoader={true} color={'#001F59'} />
            <PegButton
              id="refreshModalButton"
              type="button"
              variant="Primary"
              data-testid="refreshModalButton"
              styleObject={{ position: 'relative', top: '15px', display: 'flex', margin: 'auto' }}
              onClick={() => window.location.reload()}
            >
              Refresh Page
            </PegButton>
          </div>
        </PegModal>
      </div>
    )
  }

  if (isLoading || exchangeRatesLoading) {
    return (
      <LoaderOverview data-testid="jobgridLoader" role="jobgridLoader">
        {exchangeError ? <LoaderErrorMessage>Could not load currency exchange data</LoaderErrorMessage> : null}
        <Loader
          size={50}
          color={'#adccf4'}
        />
      </LoaderOverview>
    );
  }

  if (isError) {
    return (
      <div data-testid="jobGridError">
        something went wrong
      </div>
    );
  }

  const showVesselDetails = jobDetails?.VesselImoNumber && jobDetails?.VesselImoNumber?.toString().trim().length > 0 && jobDetails?.VesselImoNumber !== "N/A"
  return (
    <JobServices data-testid="jobGridServices">
      {(isLoading || exchangeRatesLoading) && <LoaderOverview data-testid="jobgridLoader" role="jobgridLoader">
        {exchangeError ? <LoaderErrorMessage>Could not load currency exchange data</LoaderErrorMessage> : null}
        <Loader
          size={50}
          color={'#adccf4'}
        />
      </LoaderOverview>}
      {gridErrors.length ? <GridErrors errors={gridErrors} /> : null}

      <SummaryDetails
        data-testid="jobGridSummaryDetails"
        services={services}
        jobDetails={jobDetails}
        userDetails={userDetails}
      />
      <HeaderOptions
        data-testid="jobGridHeader"
        services={services}
        selectedList={selectedList}
        jobDetails={jobDetails}
        disableCost={disableCost}
        disableServiceRequest={disableServiceRequest}
        disableOperatingIncome={disableOperatingIncome}
        disableRevenue={disableRevenue}
        disableBilling={disableBilling}
        isModified={isModified}
        showOperatingIncome={showOperatingIncome}
        showServReq={showServReq}
        showBilling={showBilling}
        onClickImportTemplate={onClickImportTemplate}
        onClickPriceSummary={onClickPriceSummary}
        onClickOptions={(type: any) => onClickOptions(type)}
        onClickFilterColumn={onClickFilterColumn}
        templateImportEnabled={!isGridReadonly}
      />
      {showVesselDetails && <div className='absolute right-0 top-96 transform -rotate-90 w-8 shadow-lg' style={{ zIndex: 100 }}>
        <PegButton
          id="vesssel_measure"
          type="button"
          variant="Primary"
          styleObject={{ minWidth: '10rem' }}
          onClick={() => setShowVesselMeasureModal(true)}
        >
          Vessel Measure
        </PegButton>
      </div>}
      <div id="vesselMeasureModal">
        <QuotationSummary onClose={() => setShowVesselMeasureModal(false)} showVesselMeasureModal={showVesselMeasureModal} />
      </div>
      <JobTable
        disableCost={disableCost}
        disableServiceRequest={disableServiceRequest}
        disableOperatingIncome={disableOperatingIncome}
        disableRevenue={disableRevenue}
        disableBilling={disableBilling}
        showOperatingIncome={showOperatingIncome}
        showServReq={showServReq}
        showBilling={showBilling}
        expandAll={expandAll}
        services={services}
        columnPreference={columnPreference}
        jobDetails={jobDetails}
        serviceList={serviceList}
        uomList={uomList}
        voucherList={voucherList}
        selectedList={selectedList}
        isAllSelected={isAllSelected}
        onExpandAll={onExpandAll}
        currencyList={currencyList}
        billingParties={billingParties}
        taxList={taxList}
        exchangeRates={exchangeRates}
        paymentTerms={paymentTerms}
        onDeleteService={deleteServices}
        onSelectServices={(index: number) => onSelectServices(index)}
        onDeleteCharge={deleteCharges}
        addCharges={addCharges}
        onUpdateCharge={onUpdateCharge}
        onUpdateService={onUpdateService}
        onUpdateEditService={onUpdateEditService}
        onUpdateServiceDetails={onUpdateServiceDetails}
        onSelectAll={() => onSelectAll()}
        userDetails={userDetails}
        onShowTariff={(tariff: any) => onShowTariffModal(tariff)}
        isValidParty={isValidParty}
        setValidParty={setValidParty}
        setPartyValidModal={setPartyValidModal}
        chargeValidation={chargeValidation}
        serviceValidation={serviceValidation}
        masterChargeValidation={masterChargeValidation}
        masterServiceValidation={masterServiceValidation}
        updateChargeValidation={updateChargeValidation}
        isReadOnly={isGridReadonly}
      />
      {!isGridReadonly ? <FooterOptions
        services={services}
        missingValidation={missingValidation}
        hasMissingFields={hasMissingFields}
        selectedList={selectedList}
        onAddService={onAddService}
        onDeleteOption={onDeleteOption}
        onEditOption={onEditOption}
        onSave={onSave}
        isModified={isModified}
        onUndoChange={onUndoChange}
        onApplyTariff={onApplyTariff}
        isServiceAdding={isServiceAdding}
        isAnyServiceWithSupplierInvoiceOrCR={isAnyServiceWithSupplierInvoiceOrCRSelected}
      /> : null}
      <PegModal
        isOpen={showDeleteModal}
        closeModal={onDeleteModalCancel}
        tertiaryAction={onDeleteModalConfirm}
        modalTitle={"Delete Items"}
        buttonText={"Delete"}
        modalContent={deleteConfirmationMessage}
        disableConfirmButton={noneOfTheServicesCanBeDeleted}
      />
      <PegModal
        isOpen={isJobSaving}
        alertModal={true}
        showTemplate={true}
        isCenter={true}>
        <div style={{ overflow: 'hidden', padding: 12 }}>
          <QuotationAlert data-testid="saveJobGridModal">{isJobSaved ? "Records have been saved successfully" : "Please wait while saving"}</QuotationAlert>
          {isJobSaved ?
            <BsCheckCircle style={{
              height: "50px",
              width: "100%",
              color: "#2cd334"
            }} /> :
            <Loader
              size={50}
              color={'#adccf4'}
            />}
        </div>
      </PegModal>
      <PegModal
        isOpen={applyingTarriff}
        alertModal={true}
        showTemplate={true}
        isCenter={true}>
        <div style={{ overflow: 'hidden', padding: 12 }}>
          <QuotationAlert>Please wait while fetching tariff</QuotationAlert>
          <Loader
            size={50}
            color={'#adccf4'}
          />
        </div>
      </PegModal>
      <div id="jobGridEdit">
        <PegModal
          isOpen={showEditModal}
          closeModal={onEditOptionCancel}
          tertiaryAction={editOptionConfirmPreCheck}
          modalTitle={"Edit Items"}
          buttonText={"Update"}
          isCenter={true}
          showTemplate={true}
        >
          <EditForm
            isMaster={jobDetails?.IsMaster}
            currencyList={currencyList}
            bulkEditSR={bulkEditSR}
            bulkEditList={bulkEditList}
            jobDetails={jobDetails}
            isValidParty={isValidParty}
            onEditServices={(event: any, key: any) => onEditServices(event, key)}
            isCostEditDisabled={isAnyServiceWithSupplierInvoiceOrCRSelected}
            openOnlyBillingStatus={openOnlyBillingStatus}
          />

        </PegModal>
        <PegModal
          isOpen={showConfirmBulkUpdate}
          closeModal={onBulkUpdateDenied}
          isCenter={true}
          tertiaryAction={onBulkUpdateConfirmed}
          modalTitle={"One or more services are marked as non billable so cannot be sent to billing."}
          buttonText={"Proceed"}
          modalContent={"Do you wish to proceed?"}
        />
      </div>
      <div id="importTemplateModal">
        <PegModal
          isOpen={showTemplateModal}
          alertModal={true}
          isCenter={true}
          showTemplate={true}>
          <TemplateView
            details={jobDetail}
            type={'job'}
            onSubmitImportTemplate={onSubmitImportTemplate}
            onCloseTemplateModal={onCloseTemplateModal}
          />
        </PegModal>
      </div>
      <div id="tariffModal">
        <PegModal
          isOpen={showTarrifModal}
          closeModal={onCloseTariffModal}
          alertModal={true}
          isCenter={true}
          showTemplate={true}>
          <TariffModal
            charge={tariffData}
            type="job"
            closeModal={onCloseTariffModal}
          />
        </PegModal>
      </div>
      <div id="priceSummaryModal">
        <PegModal
          isOpen={showPriceSummaryModal}
          alertModal={true}
          isCenter={true}
          showTemplate={true}>
          <PriceSummary
            onClose={onClosePriceSummary}
            jobDetail={jobDetail}
            isPriceSummaryPosting={isPriceSummaryPosting}
            sendPricingSummary={(pricingSummary: any) => sendPricingSummary(pricingSummary)}
          />
        </PegModal>
      </div>
      <div id="validPartyModal">
        <PegModal
          isOpen={showPartyValidModal}
          alertModal={true}
          showTemplate={true}
          isCenter={true}>
          <div style={{ overflow: 'hidden', padding: 12 }}>
            <QuotationAlert>Validating party status...</QuotationAlert>
            <Loader
              size={100}
              color={'blue'}
            />
          </div>
        </PegModal>
      </div>
      <div id="filterColumnModal">
        <PegModal
          isOpen={showFilterModal}
          closeModal={onClickFilterColumn}
          alertModal={true}
          isCenter={true}
          showTemplate={true}>
          <Preferences
            details={jobDetail}
            type={'job'}
            column={columnPreference}
            onCloseFilterModal={onClickFilterColumn}
            onSavePreferences={(list: ColumnPreferences) => onSavePreferences(list)}
          />
        </PegModal>
      </div>
      <PegModal
        isOpen={showUndoModal}
        closeModal={onUndoModalCancel}
        tertiaryAction={onUndoModalConfirm}
        modalTitle={"Undo Changes"}
        buttonText={"Clear"}
        modalContent={"Are you sure to Undo the changes"}
      />
    </JobServices>
  )

}

export default JobGrid

