import {
  useCallback,
  useState,
  useEffect,
} from 'react';
import ToolTip from 'Controls/ToolTip/ToolTip'
import { FaEdit } from 'react-icons/fa';
import Dropdown from 'Controls/Dropdown';
import DatePicker from 'Controls/DateRange/PegDatePicker';
import InputBox from 'Controls/Input/PegInputBox';
import { ReactComponent as Expanded } from 'Assets/arrow-down.svg'
import { ReactComponent as Collapsed } from 'Assets/arrow-up.svg'
import { ReactComponent as AddNew } from 'Assets/add-new.svg'
import { ReactComponent as DeleteIcon } from 'Assets/delete-item.svg'
import axios from 'axios';
import {
  setServiceValue,
  setServiceRequestDetails,
  getStatus
} from 'Utils/JobGrid';
import {
  getExchangeRates, showToast
} from 'Utils/Generic';
import {
  SERVICE_STATUS_OPTIONS,
  BILLING_STATUS_OPTIONS,
  BILLING_METHOD_OPTIONS,
  VOUCHER_TYPE_OPTIONS
} from 'Constants/DropdownOptions'
import { BILLING_STATUS, BILLING_STATUS_OPTIONS_LIST, SERVICE_STATUS, VOUCHER_STATUS, VOUCHER_STATUS_VALUES, VoucherType } from 'Constants/Constant';
import {
  OverviewHeader,
  // list
  ID,
  Delete,
  Add,
  Expand,
  Detail,
  Name,
  BillingParty,
  CustomerService,
  //DateRange
  StartDate,
  EndDate,
  //revenue
  Quantity,
  UOM,
  UnitPrice,
  Currency,
  Amount,
  BillingCurrency,
  ExRate,
  Tax,
  SalesTaxAmt,
  SalesInvoice,
  BillAmount,
  AdjBillAmount,
  //cost
  Supplier,
  EstUnitCost,
  EstCurrency,
  EstExRate,
  EstTaxType,
  EstTax,
  EstAmt,
  EstAmtTax,
  IsBillable,
  BillingStatus,
  BillingOptions,
  PaymentMethod,
  BackToBack,
  // sr request
  SrNo,
  SrStatus,
  ServiceStatus,
  // operating income 
  OperatingIncome,
  // eye_share
  EsActualCost,
  SupplierInvoiceDetail,
  //credit note
  CreditAmt,
  CreditNoteRef,
  // remarks
  ExternalRemarks,
  BillingRemarks,
  InternalRemarks,
  VoucherNo,
  VoucherType as VoucherTypeDiv,
  IsCashVoucherNeeded,
  ReceivedQuantity,
  ExternallyEdited,
  BillingPartyAddress,
  ActualCostExRate
} from 'Containers/JobGrid/Jobs.styles'
import { VscExpandAll } from "react-icons/vsc";
import { AiFillEdit } from "react-icons/ai";
import { Color } from 'Utils/Color';
import { IExchangeRateMinMax } from 'Model/Common/types';
import { ICharge } from 'Model/Job/JobCharge';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { toast } from 'react-toastify';

type Props = {
  id?: number;
  services: any
  type?: string;
  jobDetails: any;
  showCharges?: boolean;
  service?: any;
  serviceIndex: number;
  serviceList: any[];
  uomList: any[];
  voucherList: any;
  currencyList: any[];
  billingParties: any[];
  taxList: any[];
  exchangeRates: any[];
  setPartyValidModal: any;
  url?: string;
  isLookup?: boolean;
  charges?: any;
  selectedList: Number[];
  isAllSelected?: any;
  isSelected?: any;
  srNoDropdown: any[];
  serviceRequestDetails?: any;
  supplierDetails: any;
  disableCost?: boolean;
  disableServiceRequest?: boolean;
  disableOperatingIncome?: boolean;
  disableRevenue?: boolean;
  disableBilling?: boolean;
  salesInvoice: any;
  onSelectAll?: any;
  serviceObject: any;
  userDetails: any;
  isValidParty: boolean;
  setValidParty: any;
  editCharge: boolean;
  columnPreference: any;
  paymentTerms: any;
  isSupplierInvoiceAvailable?: boolean,
  isSupplierCreditNoteAvailable?: boolean,

  onUpdateServiceDetails: (serviceDetailsObj: any, serviceIndex: any) => void;
  onUpdateService: (service: any, serviceIndex: any) => void;
  onSelectOptions?: (service: any, charge: any) => void;
  onExpandService?: (service: any) => void;
  onAddCharge: (service: any) => void;
  onChangeInput: (event: any, id: any, serviceIndex: any, chargeIndex: any) => void;
  onChangeDate: (event: any, id: any, serviceIndex: any, chargeIndex: any) => void;
  onChangeDropdown: (event: any, id: any, obj: any) => void;
  onDeleteService: (service: any) => void;
  onSelectServices: (index: number) => void;
  showDetailModal?: any;
  onBackToBackSelected: (service: any) => void;
  onIsBillableSelected: (service: any) => void;
  isReadOnly: boolean;
  undoB2BwhenInvoiced: () => void;
  onChangeService: (e: any, index: number) => void;
};

const ServiceLine = (props: Props) => {
  const {
    onChangeDropdown,
    service,
    serviceIndex,
    disableCost,
    disableServiceRequest,
    disableOperatingIncome,
    disableRevenue,
    onExpandService,
    showCharges,
    onDeleteService,
    onAddCharge,
    onSelectServices,
    serviceList,
    currencyList,
    serviceRequestDetails,
    supplierDetails,
    jobDetails,
    onChangeDate,
    onUpdateService,
    charges,
    selectedList,
    voucherList,
    onUpdateServiceDetails,
    onBackToBackSelected,
    onIsBillableSelected,
    serviceObject,
    srNoDropdown,
    exchangeRates,
    disableBilling,
    setValidParty,
    userDetails,
    services,
    editCharge,
    columnPreference,
    paymentTerms,
    showDetailModal,
    isSupplierInvoiceAvailable: isSupplierInvoiceAvailable,
    isSupplierCreditNoteAvailable: isSupplierCreditNoteAvailable,
    isReadOnly,
    taxList,
    undoB2BwhenInvoiced,
    onChangeService
  } = props;


  const {
    CurrencyDecimalPlaces,
    CurrencyDecimalPlacesAutoCalculation
  } = userDetails;

  const [chargeDisabled, setChargeDisabled] = useState(true);
  const [billingStatusDisabled, setBillingStatusDisabled] = useState(true);

  const [isCashVoucherNeeded, setIsCachVoucherNeeded] = useState(!!service.IsCashVoucherNeeded);

  const [showModal, setShowModal] = useState(false);

  const [paymentVoucherList, setPaymentVoucherList] = useState<any>([]);
  const [isPaymentRequestListLoading, setIsPaymentRequestLoading] = useState(false);
  const [billExRateMinMax, setBillExRateMinMax] = useState<IExchangeRateMinMax | null>(null)
  const [costExRateMinMax, setCostExRateMinMax] = useState<IExchangeRateMinMax | null>(null)
  const [billExRateError, setBillExRateError] = useState(false);
  const [costExRateError, setCostExRateError] = useState(false);

  const [hideBillExRateError, setHideBillExRateError] = useState(false);
  const [hideCostExRateError, setHideCostExRateError] = useState(false);

  let decimalPlace = CurrencyDecimalPlaces ? parseInt(CurrencyDecimalPlaces) : 2;
  let autoDecimal = CurrencyDecimalPlacesAutoCalculation ? parseInt(CurrencyDecimalPlacesAutoCalculation) : 2;
  const isEdited = services.every((el: any) => el?.service?.IsExternallyEdited === null);
  const styleClass = "text-left text-xs font-medium text-gray-500 tracking-wider bg-gray-50";
  const inputStyle = {
    border: 'none',
    height: '34px',
    borderRadius: '0px',
    fontSize: '12px',
  }

  const billExRateStyle = billExRateError ? {
    border: '1px solid red'
  } : {};
  const costExRateStyle = costExRateError ? {
    border: '1px solid red'
  } : {};

  const isDisabled = isReadOnly || chargeDisabled || !editCharge

  const isAllChargesInvoiced = charges.every((r: ICharge) => r.BillingStatus == BILLING_STATUS.INVOICED)
  const areSomeChargesInvoiced = charges.some((r: ICharge) => r.BillingStatus == BILLING_STATUS.INVOICED)
  const isPaymentTermDisabled = isReadOnly || isAllChargesInvoiced || !editCharge;

  const isVoucherTypeDisabled: boolean = service.Id ? isCashVoucherNeeded : false;


  const disableSendToBilling = isReadOnly || billingStatusDisabled;
  const disableServiceStatus = isReadOnly || chargeDisabled

  //cost section is unlocked until supplier is invoiced

  const isVoucherApproved: boolean = service?.VoucherStatus === VOUCHER_STATUS.APPROVED;
  const isCashVoucherApproved = service.VoucherType === VoucherType.Cache && isVoucherApproved;
  const isVoucherValid = ![VOUCHER_STATUS.REJECTED, VOUCHER_STATUS.CANCELLED, VOUCHER_STATUS.DELETED].includes(service.VoucherStatus);
  const hasVoucherStatus = VOUCHER_STATUS_VALUES.includes(service.VoucherStatus) || !service.VoucherStatus;

  const baseConditionForLockingFields = isReadOnly || !editCharge || service.IsFromMaster
  const commonLockingCondition = baseConditionForLockingFields || isCashVoucherApproved;

  const disableSupplier = commonLockingCondition || isSupplierInvoiceAvailable || isSupplierCreditNoteAvailable || serviceRequestDetails?.SRStatus === "Completed" || isVoucherApproved;
  const disableCostCurrencyNExchangeRate = commonLockingCondition || isSupplierInvoiceAvailable || isSupplierCreditNoteAvailable || isVoucherApproved;
  const disableIsVoucherNeeded = commonLockingCondition || isVoucherTypeDisabled || !(hasVoucherStatus && isVoucherValid) || isSupplierInvoiceAvailable || isSupplierCreditNoteAvailable;
  const disableVoucherType = commonLockingCondition || isVoucherTypeDisabled || isSupplierInvoiceAvailable || isSupplierCreditNoteAvailable;
  const disableVoucherNumber = commonLockingCondition || !service.IsCashVoucherNeeded || isVoucherApproved || !hasVoucherStatus || isSupplierInvoiceAvailable || isSupplierCreditNoteAvailable;

  const disableB2B = baseConditionForLockingFields || areSomeChargesInvoiced || isSupplierCreditNoteAvailable

  const disableAddChargeBtn = (isDisabled || isVoucherApproved || isSupplierInvoiceAvailable || isSupplierCreditNoteAvailable)
  const canDeleteService = !(isReadOnly || chargeDisabled || isSupplierInvoiceAvailable || isSupplierCreditNoteAvailable || isVoucherApproved);


  useEffect(() => {
    // const asyncFn = async () => {
    let editableBillingStatus = false;
    let editable = true;

    for (const item of charges || []) {
      if (item?.BillingStatus == undefined || item?.BillingStatus == "") {
        editableBillingStatus = true
      } else {
        editable = false;
      }
    }

    setChargeDisabled(!editable)
    setBillingStatusDisabled(!editableBillingStatus)
    // }
    // asyncFn()
  }, [charges, chargeDisabled])

  useEffect(() => {

    if (!service.SupplierCode || !jobDetails.LocalJobNumber || service?.VoucherType != VoucherType.PaymentRequest) {
      return
    }

    setIsPaymentRequestLoading(true)
    // const asyncFn = async () => {
    axios.get(`/getPaymentRequestVoucher?supplierCode=${service.SupplierCode}&jobNumber=${jobDetails.LocalJobNumber}`)
      .then((res: any) => {
        setPaymentVoucherList(res?.data)
      }).finally(() => setIsPaymentRequestLoading(false))
    // }

    // asyncFn()

  },
    [service?.SupplierCode,
    service?.VoucherType])


  useEffect(() => {

    if (service?.VoucherType != VoucherType.PaymentRequest || service?.isShowVoucherLink) {
      return
    }

    const serviceWithValidVouchers = services.filter((item: any) => {
      return (
        [VOUCHER_STATUS.OPEN, VOUCHER_STATUS.SENT].includes(item?.service?.VoucherStatus) &&
        item?.service?.SupplierCode == service?.SupplierCode &&
        item.service.Id != service.Id)
    }
    )

    const uniqueVoucherNumbers = [new Set(...serviceWithValidVouchers.map((item: any) => item?.service?.VoucherNumber))]

    if (uniqueVoucherNumbers?.length == 1 && paymentVoucherList?.length) {

      const voucher = {
        "VoucherId": serviceWithValidVouchers[0].service?.VoucherFk,
        "VoucherNumber": serviceWithValidVouchers[0].service?.VoucherNumber,
        "Status": serviceWithValidVouchers[0].service?.VoucherStatus,
        "VoucherLink": serviceWithValidVouchers[0].service?.VoucherLink
      }

      let serviceObj = setServiceValue(service, jobDetails, 'VoucherNumber', voucher);
      onUpdateService(serviceObj, serviceIndex)
    }

  }, [paymentVoucherList,
    service?.VoucherType])

  useEffect(() => {
    // const asyncFn = async () => {
    if ([VOUCHER_STATUS.REJECTED, VOUCHER_STATUS.APPROVED, VOUCHER_STATUS.CANCELLED, VOUCHER_STATUS.DELETED].includes(service.VoucherStatus)) {
      return;
    }

    if (!voucherList.some((v: any) => v.VoucherNumber === service.VoucherNumber)) {
      if (service.VoucherType === VoucherType.Cache) {
        return voucherList.push({
          "VoucherId": service.VoucherFk,
          "VoucherNumber": service.VoucherNumber,
          "Status": service.VoucherStatus
        })
      }
    }
    // }
    // asyncFn()
  }, [voucherList])

  useEffect(() => {
    // const asyncFn = async () => {
    if (!service?.BillingCurrencyCode) {
      let serviceObj = setServiceValue(service, jobDetails, 'defaultBillingCurrency', jobDetails.CurrencyCode)
      onUpdateService(serviceObj, serviceIndex)
    }
    if (!service?.PriceCurrencyCode) {
      let serviceObj = setServiceValue(service, jobDetails, 'defaultPriceCurrency', jobDetails.CurrencyCode)
      onUpdateService(serviceObj, serviceIndex)
    }
    if (!service?.CostCurrencyCode) {
      let serviceObj = setServiceValue(service, jobDetails, 'defaultCostCurrency', jobDetails.CurrencyCode)
      onUpdateService(serviceObj, serviceIndex)
    }
    if (serviceRequestDetails) {
      if (!serviceRequestDetails?.StartDateTime) {
        let serviceDetailsObj = setServiceRequestDetails(serviceObject, 'defaultStartDate', jobDetails.ETA)
        onUpdateServiceDetails(serviceDetailsObj, serviceIndex)
      }
      if (!serviceRequestDetails?.EndDateTime) {
        let serviceDetailsObj = setServiceRequestDetails(serviceObject, 'defaultEndDate', jobDetails.ETD)
        onUpdateServiceDetails(serviceDetailsObj, serviceIndex)
      }
    }

    // }
    // asyncFn()
  }, [
    serviceIndex,
    service.BillingCurrencyCode,
    service.PriceCurrencyCode,
    service.CostCurrencyCode,
    serviceRequestDetails?.StartDateTime,
    serviceRequestDetails?.EndDateTime,
  ])

  useEffect(() => {

    // const asyncFn = async () => {
    if (srNoDropdown.length === 1) {
      let serviceDetailsObj = setServiceRequestDetails(serviceObject, 'serviceSRnumber', srNoDropdown[0])
      onUpdateServiceDetails(serviceDetailsObj, serviceIndex)
    }
    if (srNoDropdown.length == 0 && !serviceObject?.serviceRequestDetails?.isShowSRLink) {
      let serviceDetailsObj = setServiceRequestDetails(serviceObject, 'serviceSRnumber', null)
      onUpdateServiceDetails(serviceDetailsObj, serviceIndex)
    }
    // }

    // asyncFn()
  }, [serviceIndex, srNoDropdown])

  useEffect(() => {
    // const asyncFn = async () => {
    if (!service?.BillingExchangeRate) {
      convertToBillingCurrency();
    }
    // }
    // asyncFn()
  }, [])

  useEffect(() => {
    // const asyncFn = async () => {
    if (!service?.PriceExchangeRate) {
      getPriceConversion();
    }
    // }
    // asyncFn()
  }, [])

  useEffect(() => {
    // const asyncFn = async () => {
    if (!service?.CostExchangeRate) {
      getCostConversion();
    }
    // }
    // asyncFn()
  }, [])

  useEffect(() => {
    if (!service?.BillingMethodCode) {
      onChangeDropdown('Credit', 'billingMethod', serviceObject)
    }
    if (!service?.PaymentTermCode) {
      let paymentTerm = jobDetails?.PaymentTermCode ? jobDetails?.PaymentTermCode :
        userDetails?.PaymentTermCode ? userDetails?.PaymentTermCode : '10';
      onChangeDropdown(paymentTerm, 'paymentMethod', serviceObject)
    }
  }, [])

  useEffect(() => {
    if (service.Id === 0 && !!jobDetails.ATD) {
      let serviceDetailsObj = setServiceRequestDetails(serviceObject, 'serviceStatus', { label: SERVICE_STATUS.COMPLETED })
      onUpdateServiceDetails(serviceDetailsObj, serviceIndex)
    }
  }, [])

  useEffect(() => {
    // if (service.VoucherStatus === VOUCHER_STATUS.REJECTED) {
    if (!isVoucherValid) {
      let serviceObj = setServiceValue(service, jobDetails, 'IsCashVoucherNeeded', false);
      onUpdateService(serviceObj, serviceIndex)
      setIsCachVoucherNeeded(false);
    }
  }, [service.VoucherStatus])

  const getCostConversion = useCallback(() => {
    let serviceObj: any;
    let costExRate: any = getExchangeRates(exchangeRates, service?.CostCurrencyCode, jobDetails?.CurrencyCode)
    if (costExRate) {
      serviceObj = setServiceValue(service, jobDetails, 'CostExchangeRate', costExRate.ExchangeRate);
    } else {
      serviceObj = setServiceValue(service, jobDetails, 'CostExchangeRate', 0);
    }
    onUpdateService(serviceObj, serviceIndex)
  }, [service]);

  const getPriceConversion = useCallback(() => {
    let serviceObj: any;
    let priceExRate: any = getExchangeRates(exchangeRates, service?.PriceCurrencyCode, jobDetails?.CurrencyCode)
    if (priceExRate) {
      serviceObj = setServiceValue(service, jobDetails, 'PriceExchangeRate', priceExRate.ExchangeRate);
    } else {
      serviceObj = setServiceValue(service, jobDetails, 'PriceExchangeRate', 0);
    }
    onUpdateService(serviceObj, serviceIndex)
  }, [service]);

  const convertToBillingCurrency = useCallback(() => {
    let fromCurrency = service?.BillingCurrencyCode;
    let targetCurrency = service?.PriceCurrencyCode;
    let serviceObj: any;
    if (jobDetails.CurrencyCode !== targetCurrency) {
      fromCurrency = service?.PriceCurrencyCode
      targetCurrency = service?.BillingCurrencyCode
    }
    let billExRate: any = getExchangeRates(exchangeRates, fromCurrency, targetCurrency)
    if (billExRate) {
      serviceObj = setServiceValue(service, jobDetails, 'BillingExchangeRate', billExRate.ExchangeRate);
    } else {
      serviceObj = setServiceValue(service, jobDetails, 'BillingExchangeRate', 0);
    }
    onUpdateService(serviceObj, serviceIndex)
  }, [service]);



  const onChangeDropdownValue = useCallback((e: any, id: any, obj: any) => {
    let { billingParties, setPartyValidModal } = props;
    let serviceRow = services[serviceIndex]

    if (id === 'billingStatus') {
      if (e.name === 'Send for Billing') {
        const isValidBillingParty = serviceRow.charges.some((charge: any) =>
          billingParties.some(party => party.operationalProcessPartyId === charge.OperationalProcessPartyFk)
        );


        if (isValidBillingParty) {
          setPartyValidModal(true);
          getStatus(jobDetails, null, serviceRow, billingParties, '', null, false).then((res: any) => {
            setPartyValidModal(false);
            setValidParty(res?.IsValidParty);
            if (res?.IsValidParty) {
              setPartyValidModal(false);
              showToast('Party is valid, you can proceed for "Send to Billing"', 'success', 7000, 'top-center', 'colored');
              onChangeDropdown(e, id, serviceObject);
            } else {
              res?.Message.map((el: any) => {
                showToast(`${el}, cannot be "Send to Billing" !`, 'error', 7000, 'top-center', 'colored');
              })
              onChangeDropdown('', id, serviceObject);
            }
          })
        } else {
          toast.error("The billing party was not selected while saving the services or while sending services for billing", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
            style: { width: '400px' },
          })
        }
      }
    } else {
      let event = (id === 'paymentMethod') ? e?.code :
        (id === 'billingMethod') ? e?.name : e
      onChangeDropdown(event, id, serviceObject);
      if (id === 'estimatedCurrency') {
        getCostConversion()

        if (service.BackToBack) {
          const priceCurrencyCode = { code: service.CostCurrencyCode }
          let serviceObj = setServiceValue(service, jobDetails, 'actualCurrency', priceCurrencyCode)
          onUpdateService(serviceObj, serviceIndex)
          getPriceConversion()
          convertToBillingCurrency()
        }
      }
      if (id === 'actualCurrency') {
        getPriceConversion();
        convertToBillingCurrency();
      }
      if (id === 'billingCurrency') {
        convertToBillingCurrency();
      }
      if (id === 'supplier') {
        setValidParty(true)
        let serviceDetailsObj = setServiceRequestDetails(serviceObject, 'isShowSRlink', false)
        onUpdateServiceDetails(serviceDetailsObj, serviceIndex)

        if (service.VoucherType == VoucherType.PaymentRequest) {
          const serviceObj = setServiceValue(service, jobDetails, 'VoucherNumber', null);
          onUpdateService(serviceObj, serviceIndex)
        }
      }
      if (id === 'VoucherNumber') {
        let serviceObj = setServiceValue(service, jobDetails, 'VoucherNumber', e);
        onUpdateService(serviceObj, serviceIndex)
      }
      if (id === 'VoucherType') {
        let serviceObj = setServiceValue(service, jobDetails, 'VoucherType', e);
        onUpdateService(serviceObj, serviceIndex)
      }

      if (id === 'serviceName' && service.Id == 0) {
        onChangeService(e, serviceIndex)
      }

      undoB2BwhenInvoiced()

    }
  }, [serviceIndex, serviceObject, props.billingParties]);

  const onClickCheckbox = (e: any) => {

    if (e?.target?.id === 'BackToBack') {

      let serviceObj = setServiceValue(service, jobDetails, 'BackToBack', e.target.checked);
      onUpdateService(serviceObj, serviceIndex)
      onBackToBackSelected(serviceObj);

    } else if (e.target.id === 'voucherStatus') {
      let serviceObj = setServiceValue(service, jobDetails, 'IsCashVoucherNeeded', e.target.checked)
      onUpdateService(serviceObj, serviceIndex)

    }

    if (!(isDisabled && billingStatusDisabled)) {
      if (e.target.id === 'IsBillable') {
        let serviceObj = setServiceValue(service, jobDetails, 'IsBillable', e.target.checked)
        onUpdateService(serviceObj, serviceIndex)
        onIsBillableSelected(serviceObj);
      }
      else if (e.target.id === 'onSelect') {
        onSelectServices(serviceIndex)
      }
    }
  }

  const editVoucherStatus = useCallback(() => {
    let serviceObj = setServiceValue(service, jobDetails, 'isShowVoucherLink', false)
    onUpdateService(serviceObj, serviceIndex)
  }, [serviceIndex]);

  const onChangeDateValue = (e: any, id: any) => {
    onChangeDate(e, id, serviceIndex, null);
  }

  const onClickAdd = () => {
    if (!disableAddChargeBtn) {
      onAddCharge(serviceIndex)
    }
  }

  const onClickDelete = () => {
    if (canDeleteService) {
      onDeleteService(serviceIndex)
    }
  }

  const validateBillingExchangeRate = (newExchangeRate: number) => {
    let fromCurrency = service?.BillingCurrencyCode
    let targetCurrency = jobDetails.CurrencyCode

    if (service?.PriceCurrencyCode !== jobDetails.CurrencyCode) {
      fromCurrency = service?.PriceCurrencyCode
      targetCurrency = service?.BillingCurrencyCode
    }

    const exchangeRate: any = getExchangeRates(exchangeRates, fromCurrency, targetCurrency);

    if (exchangeRate) {
      setBillExRateMinMax({
        min: exchangeRate?.MinExRate,
        max: exchangeRate?.MaxExRate
      })
    }

    const invalidExchangeRate = !!(exchangeRate && (newExchangeRate > exchangeRate?.MaxExRate || newExchangeRate < exchangeRate?.MinExRate))

    setBillExRateError(invalidExchangeRate);
    setHideBillExRateError(!invalidExchangeRate);
  }

  const validateCostExchangeRate = (newExchangeRate: number) => {
    const exchangeRate: any = getExchangeRates(exchangeRates, service?.CostCurrencyCode, jobDetails?.CurrencyCode)
    if (exchangeRate) {
      setCostExRateMinMax({
        min: exchangeRate?.MinExRate,
        max: exchangeRate?.MaxExRate
      })
    }

    const invalidExchangeRate = !!(exchangeRate && (newExchangeRate > exchangeRate?.MaxExRate || newExchangeRate < exchangeRate?.MinExRate))
    setCostExRateError(invalidExchangeRate);
    setHideCostExRateError(!invalidExchangeRate);
  }

  const onChangeInputValue = (event: any) => {
    if (event?.target?.type === 'number') {
      event.target.value = (event.target.value.indexOf(".") >= 0) ? event.target.value.substr(0, event.target.value.indexOf(".")) + event.target.value.substr(event.target.value.indexOf("."), decimalPlace + 1) : event.target.value;

      if (event.target?.id === "billingExchangeRate") {
        validateBillingExchangeRate(event.target.value);
      }

      if (event.target?.id === "costExchangeRate") {
        validateCostExchangeRate(event.target.value);
        undoB2BwhenInvoiced()
      }

    }
    let serviceObj = setServiceValue(service, jobDetails, event.target?.id, event)
    onUpdateService(serviceObj, serviceIndex)
  }


  const onClickEditService = useCallback(() => {
    if (!isDisabled) showDetailModal();
  }, [isDisabled])

  const filteredVoucherList = voucherList && voucherList.filter((x: any) => x.Status !== VOUCHER_STATUS.REJECTED && x.Status !== VOUCHER_STATUS.APPROVED);

  const isBPSetForCharges = charges.some((charge: any) => charge.OperationalProcessPartyFk);

  const ShowSupplier = () => !disableCost && columnPreference['SUPPLIER']?.isActive &&
    <Supplier isService data-testid="supplier">
      <Dropdown
        defaultValue={service.SupplierName}
        id={'supplier'}
        type={'charge'}
        isLookup={true}
        dropdownLabel={'name'}
        dropdownValue={'name'}
        appendParams={false}
        useDebounce={true}
        style={{ 'backgroundColor': `${Color.cost}`, width: 199 }}
        removeBorder={true}
        isMandatory={service?.Name}
        isMenuPortalTarget={'true'}
        domId={'stickyPosition'}
        placeHolder="Select supplier"
        url={`/mdm-search-supplier?serviceCode=${service?.Code}&companyCode=${jobDetails?.CompanyCode}&searchText=`}
        disabled={disableSupplier}
        dataTestId={'supplier'}
        onChangeDropdown={onChangeDropdownValue} />
    </Supplier>

  return (
    <OverviewHeader className={styleClass} id="stickyPosition" data-testid="serviceJobGrid">

      <ID isService>
        <input
          type="checkbox"
          id="onSelect"
          disabled={isDisabled && billingStatusDisabled}
          onChange={onClickCheckbox}
          checked={selectedList.includes(serviceIndex)}
          style={!(isDisabled && billingStatusDisabled) ? { cursor: 'pointer', marginRight: '8px' } : { cursor: 'not-allowed', opacity: 0.5 }} />
        {serviceIndex + 1}
      </ID>
      <ToolTip
        id="deleteToolTip"
        content={'Delete'}
        placement={'right'}
        animation={'scale-subtle'}
        arrow={true}
        duration={200}
        delay={[75, 0]}
      >
        <Delete
          isService
          data-testid='deleteIconWrapper'
        >
          <DeleteIcon
            data-testid="deleteIcon"
            onClick={onClickDelete}
            style={canDeleteService ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: 0.5 }} />
        </Delete>
      </ToolTip>
      <ToolTip
        id="addToolTip"
        content={'Add Charge'}
        placement={'right'}
        animation={'scale-subtle'}
        arrow={true}
        duration={200}
        delay={[75, 0]}
      >
        <Add
          isService
          data-testid="addIconWrapper"
        >
          <AddNew
            data-testid="addIcon"
            style={!disableAddChargeBtn ?
              { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: 0.5 }}
            onClick={onClickAdd} />
        </Add>
      </ToolTip>
      <ToolTip
        id="expandToolTip"
        content={'Hide/Show'}
        placement={'right'}
        animation={'scale-subtle'}
        arrow={true}
        duration={200}
        delay={[75, 0]}
      >
        <Expand isService >
          <div onClick={onExpandService}>
            {
              showCharges ?
                <Collapsed style={{ cursor: 'pointer' }} />
                :
                <Expanded style={{ cursor: 'pointer' }} />
            }
          </div>
        </Expand>
      </ToolTip>
      <Detail isService>
        <ToolTip
          id="expandToolTip"
          content={'Edit Service and Charges'}
          placement={'right'}
          animation={'scale-subtle'}
          arrow={true}
          duration={200}
          delay={[75, 0]}
        >
          <VscExpandAll
            className="icon-style"
            style={!isDisabled ?
              { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: 0.5 }}
            onClick={onClickEditService}
          />
        </ToolTip>
      </Detail>
      {service?.IsExternallyEdited === true ?
        <ExternallyEdited isService>
          <ToolTip
            id="isExternallyEditedTooltip"
            content={'Edited Externally'}
            placement={'right'}
            animation={'scale-subtle'}
            arrow={true}
            duration={200}
            delay={[75, 0]}
          >
            <FaEdit
              data-tip
              data-for='errorIcon'
              style={{ color: 'red' }}
              className="icon-style-md" />
          </ToolTip>

        </ExternallyEdited> : !isEdited ? <ExternallyEdited isService /> : null}
      <Name isService data-testid="serviceName">
        <Dropdown
          defaultValue={service.Name}
          onChangeDropdown={onChangeDropdownValue}
          label={'name'}
          dropdownOptions={serviceList}
          dropdownLabel={'name'}
          dropdownValue={'name'}
          style={{ width: 199 }}
          placeHolder="Select service"
          dropdownMap={'services'}
          isMenuPortalTarget={'true'}
          domId={'stickyPosition'}
          removeBorder={true}
          id="serviceName"
          dataTestId="serviceName"
          isMandatory={true}
          removeIsClear={true}
          disabled={service.Name && service.Id !== 0 ? true : isDisabled}
        />
      </Name>
      {columnPreference['CUSTOMER_SERVICE']?.isActive && <CustomerService isService />}
      {jobDetails.ProductCode === 'BP0004' && columnPreference['START_DATE']?.isActive &&
        <StartDate isService>
          <DatePicker
            value={serviceRequestDetails && serviceRequestDetails?.StartDateTime}
            style={{ height: 35 }}
            id={'startDate'}
            type={"dateTime"}
            timeFormat={'HH:mm'}
            format={'dd/MM/yyyy HH:mm'}
            enabled={!isDisabled}
            disableBorder={true}
            onChange={onChangeDateValue}
          />
        </StartDate>}
      {jobDetails.ProductCode === 'BP0004' && columnPreference['END_DATE']?.isActive &&
        <EndDate isService>
          <DatePicker
            value={serviceRequestDetails && serviceRequestDetails?.EndDateTime}
            style={{ height: 35 }}
            id={'endDate'}
            type={"dateTime"}
            timeFormat={'HH:mm'}
            format={'dd/MM/yyyy HH:mm'}
            enabled={!isDisabled}
            disableBorder={true}
            onChange={onChangeDateValue}
          />
        </EndDate>}



      {!disableOperatingIncome && columnPreference['OPERATING_INCOME']?.isActive &&
        <OperatingIncome groupColor={Color.operatingIncome} isService />}
      {!disableRevenue && columnPreference['BILLING_PARTY']?.isActive &&
        <BillingParty groupColor={Color.revenue} isService />}
      {!disableRevenue && columnPreference['BILLING_PARTY_ADDRESS']?.isActive &&
        <BillingPartyAddress groupColor={Color.revenue} isService />}

      {!disableRevenue && <UOM isService groupColor={Color.revenue} />}
      {!disableRevenue && <Quantity isService groupColor={Color.revenue} />}
      {!disableRevenue && columnPreference['UNIT_PRICE']?.isActive &&
        <UnitPrice groupColor={Color.revenue} isService />}
      {!disableRevenue && columnPreference['CURRENCY']?.isActive &&
        <Currency groupColor={Color.revenue} isService>
          <Dropdown
            defaultValue={service.PriceCurrencyCode}
            onChangeDropdown={onChangeDropdownValue}
            label={'code'}
            removeBorder={true}
            isLookup={false}
            dropdownOptions={currencyList}
            dropdownLabel={'code'}
            dropdownValue={'code'}
            placeHolder="Select currency"
            id="actualCurrency"
            appendParams={false}
            isMandatory={true}
            style={{ 'backgroundColor': `${Color.revenue}`, width: 79 }}
            isMenuPortalTarget={'true'}
            domId={'stickyPosition'}
            disabled={isDisabled || service.BackToBack}
            removeIsClear={true}
          />
        </Currency>}
      {!disableRevenue && columnPreference['AMOUNT']?.isActive &&
        <Amount groupColor={Color.revenue} isService />}
      {!disableRevenue && columnPreference['BILLING_CURRENCY']?.isActive &&
        <BillingCurrency groupColor={Color.revenue} isService>
          <Dropdown
            defaultValue={service.BillingCurrencyCode}
            onChangeDropdown={onChangeDropdownValue}
            label={'code'}
            removeBorder={true}
            isLookup={false}
            dropdownOptions={currencyList}
            dropdownLabel={'code'}
            dropdownValue={'code'}
            placeHolder="Select currency"
            id="billingCurrency"
            appendParams={false}
            isMandatory={true}
            isMenuPortalTarget={'true'}
            domId={'stickyPosition'}
            style={{ 'backgroundColor': `${Color.revenue}`, width: 85 }}
            disabled={isDisabled}
            removeIsClear={true}
          />
        </BillingCurrency>}
      {service.BillingCurrencyCode === service.PriceCurrencyCode ?
        (!disableRevenue && columnPreference['EX_RATE']?.isActive &&
          <ExRate groupColor={Color.revenue} isService>{service.BillingExchangeRate ? service.BillingExchangeRate.toFixed(autoDecimal) : ''}
          </ExRate>)
        :
        (!disableRevenue && columnPreference['EX_RATE']?.isActive &&
          <ExRate
            groupColor={Color.revenue}
            isService
            data-tip
            data-tooltip-id={"billingExchangeRate" + serviceIndex}
          >
            <InputBox
              value={service.BillingExchangeRate}
              type={'number'}
              placeholder={'Enter Exchange Rate'}
              onChange={onChangeInputValue}
              id="billingExchangeRate"
              style={{ ...inputStyle, ...billExRateStyle }}
              disabled={isDisabled}
              isMandatory={true}
              nonZeroNumber
              onBlur={() => setHideBillExRateError(true)}
              onFocus={() => validateBillingExchangeRate(service.BillingExchangeRate)}
            />
            <ReactTooltip
              id={"billingExchangeRate" + serviceIndex}
              place='right'
              isOpen={billExRateError && !hideBillExRateError}
              variant="error"
            >
              <span>Exchange rate is out of range - Min({billExRateMinMax?.min}) and Max({billExRateMinMax?.max})</span>
            </ReactTooltip>
          </ExRate>)}
      {!disableRevenue && columnPreference['TAX_RATE']?.isActive &&
        <Tax groupColor={Color.revenue} isService />}
      {!disableRevenue && columnPreference['SALES_TAX_AMT']?.isActive &&
        <SalesTaxAmt groupColor={Color.revenue} isService />}
      {!disableRevenue && columnPreference['BILL_AMT']?.isActive &&
        <BillAmount groupColor={Color.revenue} isService />}
      {!disableRevenue && columnPreference['ADJ_BILL_AMT']?.isActive &&
        <AdjBillAmount groupColor={Color.revenue} isService />}
      <ShowSupplier />
      {!disableCost && columnPreference['RECIEVED_QTY']?.isActive && <ReceivedQuantity isService groupColor={Color.cost} />}
      {!disableCost && jobDetails?.ShowCostQuantity && columnPreference['COST_UOM']?.isActive && <UOM isService groupColor={Color.cost} />}
      {!disableCost && jobDetails?.ShowCostQuantity && columnPreference['COST_QTY']?.isActive && <Quantity isService groupColor={Color.cost} />}
      {!disableCost && columnPreference['UNIT_COST']?.isActive &&
        <EstUnitCost groupColor={Color.cost} isService />}
      {!disableCost && columnPreference['COST_CURRENCY']?.isActive &&
        <EstCurrency isService>
          <Dropdown
            id="estimatedCurrency"
            defaultValue={service.CostCurrencyCode}
            onChangeDropdown={onChangeDropdownValue}
            label={'code'}
            removeBorder={true}
            isLookup={false}
            dropdownOptions={currencyList}
            dropdownLabel={'code'}
            dropdownValue={'code'}
            isMandatory={true}
            isMenuPortalTarget={'true'}
            domId={'stickyPosition'}
            placeHolder="Select currency"
            appendParams={false}
            disabled={disableCostCurrencyNExchangeRate}
            style={{ 'backgroundColor': `${Color.cost}`, width: 79 }}
            removeIsClear={true}
          />
        </EstCurrency>}
      {service?.CostCurrencyCode === jobDetails?.CurrencyCode ?
        (!disableCost && columnPreference['COST_EX_RATE']?.isActive && <EstExRate groupColor={Color.cost} isService>
          {service?.CostExchangeRate ? service?.CostExchangeRate.toFixed(autoDecimal) : ''}
        </EstExRate>)
        :
        (!disableCost && columnPreference['COST_EX_RATE']?.isActive &&
          <EstExRate
            groupColor={Color.cost}
            data-tip
            data-tooltip-id={"costExchangeRate" + serviceIndex}
          // data-event="click"
          >
            <InputBox
              value={service.CostExchangeRate}
              type={'number'}
              placeholder={'Enter Exchange Rate'}
              onChange={onChangeInputValue}
              id="costExchangeRate"
              style={{ ...inputStyle, 'backgroundColor': `${Color.cost}`, ...costExRateStyle }}
              disabled={disableCostCurrencyNExchangeRate}
              onBlur={() => setHideCostExRateError(true)}
              onFocus={() => validateCostExchangeRate(service.CostExchangeRate)}
            />

            <ReactTooltip
              id={"costExchangeRate" + serviceIndex}
              place='right'
              isOpen={costExRateError && !hideCostExRateError}
              variant="error"
            >
              <span>Exchange rate is out of range - Min({costExRateMinMax?.min}) and Max({costExRateMinMax?.max})</span>
            </ReactTooltip>
          </EstExRate>)}
      {!disableCost && columnPreference['COST_TAX_RATE']?.isActive &&
        <EstTaxType groupColor={Color.cost} isService />}
      {!disableCost && columnPreference['PURCHASE_TAX']?.isActive &&
        <EstTax groupColor={Color.cost} isService />}
      {!disableCost && columnPreference['AMT']?.isActive &&
        <EstAmt groupColor={Color.cost} isService />}
      {!disableCost && columnPreference['AMT_WITH_TAX']?.isActive &&
        <EstAmtTax groupColor={Color.cost} isService />}
      {!disableCost && columnPreference['SUPPLIER_INVOICE']?.isActive &&
        <SupplierInvoiceDetail groupColor={Color.cost} isService >
          {supplierDetails ?
            supplierDetails?.supplierInvoice?.map((el: any, index: any) => {
              return (
                <a
                  href={el?.supplierInvoiceLink}
                  key={index}
                  target="_blank"
                  style={{ color: '#0645AD', display: 'inline-block' }} rel="noreferrer">
                  {
                    el.supplierInvoiceNumber
                  }
                  {index === supplierDetails?.supplierInvoice.length - 1 ? "" : ","}
                </a>
              )
            })
            : <div />}
        </SupplierInvoiceDetail>}
      {!disableCost && columnPreference['CREDIT_NOTE']?.isActive &&
        <CreditNoteRef groupColor={Color.cost} isService >
          {
            supplierDetails
              ? supplierDetails?.creditNote?.map((el: any, index: any) => {
                return (
                  <a
                    href={el?.creditNoteLink}
                    key={index}
                    style={{ color: '#0645AD', display: 'inline-block' }}>{
                      el.creditNoteNumber
                    }
                    {index === supplierDetails?.creditNote.length - 1 ? "" : ","}
                  </a>)
              })
              : <div />
          }
        </CreditNoteRef>}
      {!disableCost && columnPreference['CREDIT_AMT']?.isActive &&
        <CreditAmt groupColor={Color.cost} isService />}
      {!disableCost && columnPreference['ACTUAL_COST']?.isActive &&
        <EsActualCost groupColor={Color.cost} isService />}
      {!disableCost && columnPreference['ACTUAL_COST_EXCHANGE_RATE']?.isActive &&
        <ActualCostExRate groupColor={Color.cost} isService>

        </ActualCostExRate>}
      {!disableCost && columnPreference['IS_VOUCHER_NEEDED']?.isActive &&
        <IsCashVoucherNeeded groupColor={Color.cost} isService>
          <input
            type="checkbox"
            id="voucherStatus"
            disabled={disableIsVoucherNeeded}
            onClick={onClickCheckbox}
            checked={service.IsCashVoucherNeeded}
            style={{ cursor: 'pointer', marginRight: '8px' }} />
        </IsCashVoucherNeeded>}
      {!disableCost && columnPreference['VOUCHER_TYPE']?.isActive &&
        <VoucherTypeDiv groupColor={Color.cost} isService>
          {service.IsCashVoucherNeeded ? <Dropdown
            id="VoucherType"
            defaultValue={service.VoucherType}
            onChangeDropdown={onChangeDropdownValue}
            label={'code'}
            removeBorder={true}
            isLookup={false}
            dropdownOptions={VOUCHER_TYPE_OPTIONS}
            dropdownLabel={'label'}
            dropdownValue={'code'}
            isMandatory={true}
            isMenuPortalTarget={'true'}
            domId={'stickyPosition'}
            placeHolder="Select option"
            appendParams={false}
            disabled={disableVoucherType}
            style={{ 'backgroundColor': `${Color.cost}`, width: 149 }}
            removeIsClear={true}
          /> : null}
        </VoucherTypeDiv>}
      {!disableCost && columnPreference['VOUCHER_NUMBER']?.isActive &&
        <VoucherNo groupColor={Color.cost} isService>
          {service.IsCashVoucherNeeded && isVoucherValid ?
            <>
              {[VoucherType.Cache, VoucherType.PaymentRequest].includes(service.VoucherType) && !service?.isShowVoucherLink ?
                <Dropdown
                  isLoading={isPaymentRequestListLoading}
                  id="VoucherNumber"
                  defaultValue={service.VoucherNumber}
                  type={'charge'}
                  label={'VoucherNumber'}
                  isLookup={false}
                  dropdownOptions={service.VoucherType == VoucherType.Cache ? filteredVoucherList : paymentVoucherList}
                  dropdownLabel={'VoucherNumber'}
                  dropdownValue={'VoucherNumber'}
                  domId={'jobChargeView'}
                  appendParams={false}
                  style={{ 'backgroundColor': `${Color.cost}`, width: 159 }}
                  placeHolder={"Select Voucher No"}
                  removeBorder={true}
                  isMandatory={false}
                  removeIsClear={true}
                  onChangeDropdown={onChangeDropdownValue}
                  disabled={disableVoucherNumber}
                /> :
                <>
                  <a style={{ color: '#0645AD' }}
                    href={service.VoucherLink}
                    target="_blank"
                    rel="noreferrer"
                    data-tip
                    data-for='voucherLink'>
                    {service?.VoucherNumber}
                  </a>
                  {service.VoucherType === VoucherType.Cache && !isVoucherApproved ? <span>
                    <AiFillEdit
                      className="icon-style-sm"
                      style={{ color: 'blue', fontSize: '12px' }}
                      onClick={editVoucherStatus}
                    />
                  </span> : null}
                </>
              }


            </> : null
          }
        </VoucherNo>}
      {
        !disableCost && columnPreference['BACK_TO_BACK']?.isActive && <BackToBack groupColor={Color.cost} isService>
          <input
            type="checkbox"
            id="BackToBack"
            disabled={disableB2B}
            onClick={onClickCheckbox}
            checked={service.BackToBack}
            style={{ cursor: 'pointer' }} />
        </BackToBack>
      }
      {
        !disableServiceRequest && columnPreference['SR_NO']?.isActive &&
        <SrNo groupColor={Color.serviceRequest} isService>
          {(serviceRequestDetails && !serviceRequestDetails?.isShowSRLink) ?
            <Dropdown
              id="serviceSRnumber"
              defaultValue={serviceRequestDetails?.SRNumber}
              onChangeDropdown={onChangeDropdownValue}
              isLookup={false}
              type={'charge'}
              dropdownOptions={srNoDropdown}
              dropdownLabel={'name'}
              dropdownValue={'name'}
              placeHolder="Select Option"
              style={{ 'backgroundColor': `${Color.serviceRequest}`, width: 174 }}
              isMenuPortalTarget={'true'}
              domId={'stickyPosition'}
              removeBorder={true}
              disabled={isDisabled}
            />
            :
            (!isReadOnly ? <a style={{ color: '#0645AD' }}
              href={supplierDetails.serviceRequestLink}
              target="_blank" rel="noreferrer">
              {serviceRequestDetails?.SRNumber}
            </a> : <span>{serviceRequestDetails?.SRNumber}</span>)
          }
        </SrNo>}
      {!disableServiceRequest && columnPreference['SR_STATUS']?.isActive &&
        <SrStatus groupColor={Color.serviceRequest} isService>
          {serviceRequestDetails && serviceRequestDetails?.SRStatus}
        </SrStatus>
      }
      {
        !disableServiceRequest && columnPreference['SERVICE_STATUS']?.isActive &&
        <ServiceStatus groupColor={Color.serviceRequest}>
          <Dropdown
            id="serviceStatus"
            defaultValue={serviceRequestDetails && serviceRequestDetails?.ServiceStatus}
            onChangeDropdown={onChangeDropdownValue}
            isLookup={false}
            type={'charge'}
            dropdownOptions={SERVICE_STATUS_OPTIONS}
            dropdownLabel={'label'}
            dropdownValue={'label'}
            placeHolder="Select Option"
            style={{ 'backgroundColor': `${Color.serviceRequest}`, width: 149 }}
            isMenuPortalTarget={'true'}
            domId={'stickyPosition'}
            removeBorder={true}
            disabled={disableServiceStatus || serviceRequestDetails && serviceRequestDetails?.SRStatus === 'Completed'}
          />
        </ServiceStatus>
      }

      {
        !disableBilling && columnPreference['IS_BILLABLE']?.isActive && <IsBillable groupColor={Color.billing}>
          <input
            type="checkbox"
            id="IsBillable"
            disabled={isDisabled}
            onClick={onClickCheckbox}
            checked={service.IsBillable}
            style={{ cursor: 'pointer' }} />
        </IsBillable>
      }

      {
        !disableBilling && columnPreference['BILLING_STATUS']?.isActive && <BillingStatus groupColor={Color.billing}>
          {serviceRequestDetails && serviceRequestDetails.ServiceStatus === 'Completed' &&
            <>
              {
                billingStatusDisabled ?
                  <></>
                  : <>
                    {
                      (jobDetails.ProductCode === 'BP0004' && !(jobDetails.ATA && jobDetails.ATD)) ?
                        <div>ATA & ATD is not available</div>
                        :
                        <div data-for={'disabledBillingStatus'} data-tip>
                          <Dropdown
                            id="billingStatus"
                            defaultValue={service.BillingStatus}
                            onChangeDropdown={onChangeDropdownValue}
                            isLookup={false}
                            type={'charge'}
                            dropdownOptions={BILLING_STATUS_OPTIONS}
                            dropdownLabel={'label'}
                            dropdownValue={'name'}
                            style={{ 'backgroundColor': `${Color.billing}`, width: 199 }}
                            isMenuPortalTarget={'true'}
                            removeBorder={true}
                            domId={'stickyPosition'}
                            disabled={disableSendToBilling}
                            removeIsClear={true}
                            placeHolder={"Select Option"}
                          />
                        </div>
                    }

                  </>}
            </>
          }
        </BillingStatus>
      }
      {
        !disableBilling && columnPreference['BILLING_METHOD']?.isActive &&
        <BillingOptions groupColor={Color.billing}>
          <Dropdown
            id="billingMethod"
            defaultValue={service && service?.BillingMethodCode}
            onChangeDropdown={onChangeDropdownValue}
            isLookup={false}
            type={'charge'}
            dropdownOptions={BILLING_METHOD_OPTIONS}
            dropdownLabel={'label'}
            dropdownValue={'name'}
            placeHolder="Select Option"
            style={{ 'backgroundColor': `${Color.billing}`, width: 149 }}
            isMenuPortalTarget={'true'}
            domId={'stickyPosition'}
            removeBorder={true}
            removeIsClear={true}
            disabled={isDisabled || isBPSetForCharges}
          />
        </BillingOptions>
      }
      {
        !disableBilling && columnPreference['PAYMENT_TERM']?.isActive &&
        <BillingOptions>
          {service.BillingMethodCode !== "Cash" ?
            <Dropdown
              id="paymentMethod"
              defaultValue={service?.PaymentTermCode}
              onChangeDropdown={onChangeDropdownValue}
              isLookup={false}
              dropdownOptions={paymentTerms}
              dropdownLabel={'name'}
              type={'charge'}
              dropdownValue={'code'}
              placeHolder="Select Option"
              style={{ 'backgroundColor': `${Color.billing}`, width: 149 }}
              isMenuPortalTarget={'true'}
              domId={'stickyPosition'}
              removeBorder={true}
              removeIsClear={true}
              disabled={isPaymentTermDisabled}
            /> : <PaymentMethod style={{ 'backgroundColor': `${Color.billing}`, width: 149 }}>Immediate</PaymentMethod>}
        </BillingOptions>
      }
      {
        !disableBilling && columnPreference['SALES_INVOICE']?.isActive &&
        <SalesInvoice groupColor={Color.billing} />
      }
      {columnPreference['INTERNAL_REMARKS']?.isActive && <InternalRemarks isService />}
      {columnPreference['EXTERNAL_REMARKS']?.isActive && <ExternalRemarks isService />}
      {columnPreference['BILLING_REMARKS']?.isActive && <BillingRemarks isService >
        {service.BillingRemarks}
      </BillingRemarks>}
    </OverviewHeader >
  )
};

export default ServiceLine;